import { useMutation, useQuery } from '@apollo/client';
import {
  GET_ORDER_BY_ID_QUERY,
  GET_SCHEDULE_BY_ID,
} from '@graphql/queries/order';
import {
  ALL_PRODUCTS_QUERY,
  GET_SUBSCRIPTION_BY_ID_NODE_QUERY,
  GET_SUBSCRIPTION_BY_NODE_QUERY,
} from '@graphql/queries/subscriptions';
import { GET_ORDERS_BY_ORDER_ID } from '@graphql/queries/order';
import { ADD_PRODUCT_FEEDBACK_MUTATION } from '@graphql/mutation/subscription';
import { updateSubscriptionDetailsCache } from '@utils/updateSubscriptionDetailsCache';
import { updateOrderDetailsCache } from '@utils/updateOrderDetailsCache';
import { SEND_REFERRAL_TO_FRIENDS } from '@graphql/mutation/sendReferralToFriends';
import { PROMO_CODE_DETAILS_MUTATION } from '@graphql/mutation/referral';
import { updateScheduleDetailsCache } from '@utils/updateScheduleDetailsCache';
import { ACCEPT_REFERRAL_CODE } from '@graphql/mutation/acceptReferralCode';

export const useOrderDetailsQuery = ({ type, id }: any) => {
  const {
    data: orderData,
    loading: orderLoading,
    error: orderError,
  } = useQuery(GET_ORDER_BY_ID_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    skip: type === 'UPCOMING',
  });
  const {
    data: scheduleData,
    loading: scheduleLoading,
    error: scheduleError,
  } = useQuery(GET_SCHEDULE_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    skip: type !== 'UPCOMING',
  });
  if (type === 'UPCOMING') {
    return {
      data: scheduleData,
      loading: scheduleLoading,
      error: scheduleError,
    };
  }
  return {
    data: orderData,
    loading: orderLoading,
    error: orderError,
  };
};

export const useSubscriptionDetailsQuery = (subscriptionId: any) => {
  const { data, loading, error } = useQuery(GET_SUBSCRIPTION_BY_NODE_QUERY, {
    variables: {
      nodeId: subscriptionId,
    },
    skip: !subscriptionId,
  });
  return {
    loading,
    data,
    error,
  };
};

export const useOrderDetailQuery = ({
  orderId,
  skipOrderQuery = true,
}: any) => {
  const { data, loading } = useQuery(GET_ORDERS_BY_ORDER_ID, {
    variables: {
      nodeId: orderId,
      type: 'ORDER',
    },
    skip: !orderId || skipOrderQuery,
  });
  return { data, loading };
};

// for order step 3
export const useSubscriptionDetailByIdQuery = (orderId: string) => {
  const { data, loading } = useQuery(GET_SUBSCRIPTION_BY_ID_NODE_QUERY, {
    variables: {
      nodeId: orderId,
      type: 'SUBSCRIPTION_RESPONSE',
    },
    skip: !orderId,
  });
  return { data, loading };
};

export const useAllProductsDetailBySubscriptionIdQuery = (
  subscriptionId: number
) => {
  const { data, loading } = useQuery(ALL_PRODUCTS_QUERY, {
    variables: {
      subscriptionId,
    },
    skip: !subscriptionId,
  });
  return { data, loading };
};

export const useProductFeedbackMutation = ({
  id,
  isUpadateCacheData,
  isOrderProduct,
  isScheduledProduct,
}: any) => {
  const [addProductFeedback, { loading: addProductFeedbackLoading }] =
    useMutation(ADD_PRODUCT_FEEDBACK_MUTATION, {
      update: (cache, { data }, { context }) => {
        if (isUpadateCacheData) {
          const { isMealProduct, isPantryProduct } = context || {};

          if (isOrderProduct) {
            updateOrderDetailsCache({
              cache,
              data,
              id,
              isMealProduct,
              isPantryProduct,
            });
          } else if (isScheduledProduct) {
            updateScheduleDetailsCache({
              cache,
              data,
              id,
              isMealProduct,
              isPantryProduct,
            });
          } else {
            updateSubscriptionDetailsCache({
              cache,
              data,
              id,
              isMealProduct,
              isPantryProduct,
            });
          }
        }
      },
    });
  return [addProductFeedback];
};

export const useSendReferralToFriendsMutation = () => {
  const [sendReferralToFriends, { loading, error, data }] = useMutation(
    SEND_REFERRAL_TO_FRIENDS
  );

  return {
    sendReferralToFriends,
    loading,
    error,
    data,
  };
};

export const useAcceptReferralCodeMutation = () => {
  const [acceptReferralCode, { loading, error, data }] = useMutation(
    ACCEPT_REFERRAL_CODE
  );

  return {
    acceptReferralCode,
    loading,
    error,
    data,
  };
};

export const useGetPromoCodeDetailsMutation = () => {
  const [getPromoCodeDetails, { loading, error, data }] = useMutation(
    PROMO_CODE_DETAILS_MUTATION
  );

  return {
    getPromoCodeDetails,
    loading,
    error,
    data,
  };
};
